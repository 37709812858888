<template>
    <v-container class='my-10'>
      <v-footer inset absolute height="75" width="auto"
        id="dashboard-core-footer"
      >
        <v-row
          no-gutters
          style="
            align-items: center;
          "
        >
        <v-col
          cols="2"
          class='ml-2'
        >
          <a target="_blank" href="https://www.carbonfootprint.com/" >
            <v-img width='100' contain style="position:absolute; top:6px; left: 6px;" src='assets/branding/cb_neutral.png'>
            </v-img>
          </a>
        </v-col>
          <v-spacer class="hidden-sm-and-down" />
          <v-col
            md="auto"
            sm="4"
          >
            <!-- Link to Privacy Policy -->
            <v-btn
              class="text-uppercase"
              plain
              href="/#/dashboard/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </v-btn>
          </v-col>
          <v-spacer class="hidden-sm-and-down" />
          <v-col
            md="auto"
            sm="4"
          >
            <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
              &copy; {{new Date().getFullYear()}}, made with
              <v-icon size="18">
                mdi-heart
              </v-icon>
              by <a target="_blank" class="footer-link" href="https://www.dooh.com">DOOH.com</a>.
            </div>
          </v-col>
        </v-row>
      </v-footer>
    </v-container>
</template>

<script >
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      links: [
        {
          href: 'https://www.dooh.com/',
          text: 'DOOH.com',
        },
      ],
    }),
  }
</script>

<style scoped>
  .v-footer {
    border: none !important;
  }
</style>

<style lang="scss">
  #dashboard-core-footer {
    a {
      color: #fff;
      font-size: .825rem;
      font-weight: 500;
      text-decoration: none;
      text-transform: uppercase;
    }
    .footer-link {
      color: var(--v-primary-base) !important;
    }
  }
  
</style>
